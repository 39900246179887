import { Suspense } from 'react'
import Link from 'next/link'
import { css, Theme } from '@emotion/react'
import { Typography, Box } from '@mui/material'
import { parseISO, format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import { GetAllNewsQuery } from 'generated/graphql.api'
import { ArrowButton } from '~/components/atoms/ArrowButton'

type Props = {
  news: GetAllNewsQuery
}

const News: React.FC<Props> = ({ news }) => {
  const newsList = news.page.edges

  return (
    <Box css={styles.container} id='news' component='section'>
      <Box>
        <Box css={styles.header}>
          <Typography variant='h1' css={styles.newTitle} color='primary'>
            News
          </Typography>
          <Typography variant='h3' css={styles.newSubTitle} color='primary'>
            ニュースリリース
          </Typography>
        </Box>
        <Box component='ul' css={styles.newsList}>
          {newsList.map(item => {
            let publishDates = parseISO(
              item.node.date ?? '2021-05-17T00:56:58.487363+00:00'
            )
            return (
              <Link
                href={`/news/${item.node.slug}`}
                key={item.node.title}
                passHref>
                <a>
                  <div css={styles.newsItem}>
                    <time
                      dateTime={item.node.date}
                      css={styles.date}
                      suppressHydrationWarning>
                      {format(publishDates, 'yyyy年M月d日', { locale: ja })}
                    </time>
                    <Typography variant='h6' css={styles.title}>
                      {item.node.title}
                    </Typography>
                    <Typography variant='caption' css={styles.excerpt}>
                      {item.node.excerpt}
                    </Typography>
                  </div>
                </a>
              </Link>
            )
          })}
        </Box>
      </Box>
      <Box css={styles.buttonContainer}>
        <ArrowButton url='news' label={'すべて見る'} />
      </Box>
    </Box>
  )
}

const styles = {
  container: css`
    max-width: 1180px;
    margin: 0 auto;
  `,
  header: css`
    padding: 150px 0 0;
    @media (max-width: 1200px) {
      margin: 0 auto;
      padding: 50px 0;
      text-align: center;
    }
    @media (max-width: 960px) {
      padding: 50px 0 30px;
    }
  `,
  newTitle: css`
    font-family: 'Montserrat', sans-serif;
    font-size: 4rem;
    font-weight: 700;
    @media (max-width: 768px) {
      margin-bottom: 1vh;
    }
  `,
  newSubTitle: css`
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  `,
  newsList: css`
    margin-left: 15vw;
    @media (max-width: 1200px) {
      margin-left: 0;
      padding: 0 10vw 50px;
    }
    @media (max-width: 960px) {
      padding: 10px 10vw 50px;
    }
  `,
  newsItem: (theme: Theme) => css`
    color: ${theme.palette.primary.main};
    height: 120px;
    border-bottom: 1px solid #928e8e;
    display: flex;
    flex-flow: column;
    justify-content: center;
    cursor: pointer;
    @media (max-width: 960px) {
      text-overflow: ellipsis;
      overflow: hidden;
      height: 130px;
    }
  `,
  date: css`
    font-family: 'Noto sans JP', sans-serif;
    font-weight: 500;
    font-size: 0.6rem;
    line-height: 1.5rem;
    color: #5f5f5f;
  `,
  title: css`
    font-family: Poppins, 'Noto sans JP', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
  `,
  excerpt: css`
    font-family: Poppins, 'Noto sans JP', sans-serif;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1.3rem;
    color: #5f5f5f;
  `,
  buttonContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px;
  `,
}

export default News
