import Link from 'next/link'
import Image from 'next/image'
import { css, keyframes, Theme } from '@emotion/react'
import { Typography } from '@mui/material'

type Props = {
  src: string
  title: string | JSX.Element[]
  subtitle: string
  href: string
}

const ServiceCard: React.FC<Props> = ({ src, title, subtitle, href }) => {
  return (
    <Link href={`/${href}`} passHref>
      <a css={styles.link}>
        <div css={styles.container}>
          <div css={styles.inner}>
            <Typography variant='h3' component='h3' css={styles.title}>
              {title}
            </Typography>
            <Typography variant='body1' component='p' css={styles.subtitle}>
              {subtitle}
            </Typography>
            <div className='arrow' css={styles.arrow}></div>
          </div>
          <div css={styles.imgContainer}>
            <Image
              src={src}
              layout='fill'
              objectFit='cover'
              css={styles.image}
            />
          </div>
        </div>
      </a>
    </Link>
  )
}
const arrowMotion = keyframes`
    from {
      left:0;
      opacity: 1;
    }
    30% {
      left: 80px;
      opacity: 0;
    }
    50% {
      left: -30px;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  `

const styles = {
  container: css`
    cursor: pointer;
    position: relative;
    width: 30vw;
    max-width: 550px;
    min-width: 400px;
    height: 500px;
    border-radius: 0px;
    &:before {
      z-index: 10;
      background-color: rgba(0, 0, 10, 0.4);
      transition: all 0.5s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
    &:hover:before {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &:hover img {
      transform: scale(1.1);
    }
    &:hover .arrow:after {
      animation: ${arrowMotion} 0.7s ease-in-out;
    }
    @media (max-width: 960px) {
      width: 100%;
      max-width: 100%;
      height: 300px;
      margin-bottom: 0;
    }
  `,
  link: css`
    text-decoration: none;
  `,
  inner: css`
    z-index: 100;
    width: 75%;
    position: absolute;
    left: 50px;
    bottom: 100px;
    @media (max-width: 960px) {
      bottom: 50px;
    }
  `,
  title: css`
    color: white;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 2rem;
    font-weight: 500;
    @media (max-width: 960px) {
      font-size: 2rem;
    }
  `,
  subtitle: css`
    color: white;
    opacity: 0.7;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
  `,
  arrow: css`
    border: 1px solid #e7e6e6;
    margin: 20px 100px 0 0;
    border-radius: 50%;
    color: white;
    position: relative;
    width: 80px;
    height: 80px;
    &:after {
      content: '\\f054';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      position: absolute;
      font-size: 24px;
      display: block;
      height: 1.4em;
      width: 0.5em;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.5s ease;
    }
    @media (max-width: 960px) {
      width: 60px;
      height: 60px;
    }
  `,
  imgContainer: css`
    z-index: 0;
  `,
  image: css`
    transition: all 0.5s ease-in-out;
  `,
}

export default ServiceCard
