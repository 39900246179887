import { useEffect } from 'react'
import { css, Theme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import Video from '~/components/molecules/Video'

const Concept = () => {
  useEffect(() => {
    if (typeof window) {
      gsap.registerPlugin(ScrollTrigger)
      setAnimation()
    }
  }, [])

  return (
    <Box css={styles.wrapper} component='section' id='concept'>
      <Box css={styles.container}>
        <Typography variant='h1' css={styles.title} color='primary'>
          ABOUT
        </Typography>
        <Box css={styles.content}>
          <Box css={styles.text}>
            <Typography
              variant='body1'
              css={styles.description}
              color='primary'
              className='c_description'>
              eduadは学習塾・教室業を専門にウェブ集客の支援を行うデジタルマーケティングオフィスです。
            </Typography>
          </Box>
          <Box css={styles.videoContainer} className='c_video'>
            <Video />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const styles = {
  wrapper: (theme: Theme) => css`
    width: 100vw;
    background-color: ${theme.palette.background.default};
  `,
  container: css`
    max-width: 1180px;
    margin: 0 auto;
  `,
  title: css`
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin: 80px 0 40px;
    @media (min-width: 960px) {
      margin: 100px 0;
      font-size: 4rem;
      text-align: left;
    }
  `,
  content: css`
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    @media (min-width: 960px) {
      flex-flow: row;
    }
  `,
  text: css`
    padding: 30px;
    @media (min-width: 1200px) {
      width: 60%;
      padding-right: 50px;
    }
  `,
  description: css`
    margin-bottom: 1rem;
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 4rem;
    @media (max-width: 1200px) {
      margin-bottom: 1rem;
      line-height: 3rem;
      text-align: center;
    }
    @media (max-width: 960px) {
      line-height: 2rem;
      text-align: start;
      font-size: 1.2rem;
    }
  `,
  videoContainer: css`
    width: 100vw;
    height: auto;
    @media (min-width: 960px) {
      width: 40%;
    }
  `,
}

const setAnimation = () => {
  gsap.from('.c_description', {
    opacity: 0,
    x: -100,
    duration: 1,
    scrollTrigger: {
      trigger: '.c_description',
      start: 'top 80%',
    },
  })
  gsap.from('.c_video', {
    opacity: 0,
    duration: 2,
    scrollTrigger: {
      trigger: '.c_video',
      start: 'top 60%',
    },
  })
}

export default Concept
