import Link from 'next/link'
import { css, keyframes, Theme } from '@emotion/react'

type ScrollDownProps = {
  label: string
  link: string
}

export const ScrollDown: React.FC<ScrollDownProps> = ({ label, link }) => {
  return (
    <div css={styles.scrollDown}>
      <Link href={`#${link}`} passHref>
        <a css={styles.head}>
          {label}
          <span css={styles.line}></span>
        </a>
      </Link>
    </div>
  )
}

const lineMotion = keyframes`
  from {
		height:0;
		top:0;
		opacity: 0;
	}
  30% {
    top: 80px;
		height: 200px;
		opacity: 0.1;
	}70% {
    top: 240px;
    opacity: 0.6;
  }
	to {
		height: 80px;
		top: 300px;
		opacity: 0;
	}
`

const styles = {
  scrollDown: css`
    position: relative;
    height: 300px;
    display: flex;
    cursor: pointer;
    opacity: 0.8;
  `,
  head: (theme: Theme) => css`
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-decoration: none;
    color: ${theme.palette.primary.main};
    writing-mode: vertical-rl;
    text-orientation: sideways-right;
    letter-spacing: 0.05rem;
    &:after {
      position: absolute;
      content: '\\f054\\f054';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      font-size: 10px;
      top: 70px;
      right: 5px;
    }
  `,
  line: (theme: Theme) => css`
    position: absolute;
    left: 9px;
    top: 70px;
    color: ${theme.palette.primary.main};
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 1.2px;
      height: 100px;
      background: ${theme.palette.primary.main};
      animation: ${lineMotion} 2s ease-in infinite;
    }
  `,
}
