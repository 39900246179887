import { useContext } from 'react'

import Hero from '~/components/organisms/Hero'
import Concept from '~/components/organisms/Concept'
import Service from '~/components/organisms/Service'
import Features from '~/components/organisms/Features'
import Works from '~/components/organisms/Works'
import Blog from '~/components/organisms/Blog'
import News from '~/components/organisms/News'
import { Loading } from '~/components/atoms/Loading'

import { HomeProps } from '~/pages/index'
import { LoadingContext } from '~/context/LoadingContext'

const HomeTemplate: React.FC<HomeProps> = ({ data, news, works }) => {
  const ctx = useContext(LoadingContext)

  return (
    <main>
      {ctx.isTriggered ? null : <Loading />}
      <Hero />
      <Concept />
      <Service />
      <Features />
      <Works works={works} />
      <Blog data={data} />
      <News news={news} />
    </main>
  )
}

export default HomeTemplate
