import Link from 'next/link'
import { css, keyframes, Theme } from '@emotion/react'
import { Button } from '@mui/material'

type Props = {
  url: string
  label: string
}

export const ArrowButton: React.FC<Props> = ({ url, label }) => {
  return (
    <Link href={`/${url}`} passHref>
      <a css={styles.link}>
        <Button variant='contained' color='primary' css={styles.button}>
          {label}
          <div className='arrow' css={styles.arrow}></div>
        </Button>
      </a>
    </Link>
  )
}

const arrowMotion = keyframes`
  from {
		left:0;
		opacity: 1;
	}
	30% {
    left: 30px;
		opacity: 0;
	}
  50% {
    left: -30px;
    opacity: 0;
  }
	to {
		left: 0;
		opacity: 1;
	}
`

const styles = {
  button: css`
    height: 60px;
    width: 250px;
    border: 2px solid;
    border-radius: 40px;
    background-color: #2c80aa;
    font-family: 'Noto sans JP', sans-serif;
    font-size: 1rem;
    font-weight: 800;
    &:hover {
      background-color: #3aa5da;
    }
    &:hover .arrow:after {
      animation: ${arrowMotion} 0.7s ease-in-out infinite;
    }
  `,
  link: css`
    text-decoration: none;
  `,
  arrow: css`
    border: 1.5px solid;
    border-radius: 50%;
    position: relative;
    margin-left: 20px;
    width: 30px;
    height: 30px;
    &:after {
      content: '>';
      position: absolute;
      font-size: 15px;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.5s ease;
    }
  `,
}
