import { useState } from 'react'
import Image from 'next/image'

import { css } from '@emotion/react'

import Thumbnail from '/public/thumbnail.webp'

const Video: React.FC = () => {
  const [isThumbnail, setIsThumbnail] = useState(true)

  return (
    <>
      <section css={styles.wrapper} id='video'>
        <div
          css={styles.video(isThumbnail)}
          onClick={() => setIsThumbnail(false)}>
          {isThumbnail ? (
            <Image src={Thumbnail} placeholder='blur' css={styles.image} />
          ) : (
            <iframe
              css={styles.frame}
              src='https://www.youtube.com/embed/UXu6-KSY86Q?autoplay=1'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen></iframe>
          )}
        </div>
      </section>
    </>
  )
}

const styles = {
  wrapper: css`
    width: 100%;
    height: auto;
    @media (max-width: 960px) {
      margin-top: 40px;
    }
  `,
  video: (isThumbnail: boolean) => css`
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    &:after {
      content: '\\f144';
      pointer-events: none;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      position: absolute;
      opacity: ${isThumbnail ? 1 : 0};
      color: red;
      font-size: 5rem;
      top: 50%;
      left: 50%;
      margin: auto;
      transform: translate(-50%, -50%);
      transition: all 0.5s ease;
    }
    &:hover:after {
      color: #ff6600;
    }
  `,
  image: css`
    width: 100%;
    height: 100%;
  `,
  frame: css`
    width: 620px;
    height: 315px;
    @media (max-width: 960px) {
      width: 95vw;
      height: 53.5vw;
    }
  `,
}

export default Video
