import Image from 'next/image'
import { css, Theme } from '@emotion/react'
import { Typography } from '@mui/material'

type Props = {
  index: string
  title: string
  description: string
  src: string
}

const FeatureCard: React.FC<Props> = ({ index, title, description, src }) => {
  return (
    <div css={styles.container}>
      <div css={styles.text}>
        <div css={styles.header}>
          <div css={styles.circle}>
            <Typography variant='caption' css={styles.index}>
              {index}
            </Typography>
          </div>
          <Typography variant='h3' component='h3' css={styles.title}>
            {title}
          </Typography>
        </div>
        <Typography variant='body1' component='p' css={styles.description}>
          {description}
        </Typography>
      </div>
      <div css={styles.imgContainer}>
        <Image src={src} layout='fill' objectFit='cover' />
      </div>
    </div>
  )
}

const styles = {
  container: (theme: Theme) => css`
    display: flex;
    width: 75vw;
    max-width: 1180px;
    height: 300px;
    background-color: white;
    border-radius: 0px;
    margin-bottom: 50px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    @media (max-width: 1280px) {
      width: 80vw;
      height: 500px;
      flex-flow: column;
    }
  `,
  text: css`
    width: 70%;
    @media (max-width: 1280px) {
      width: 100%;
    }
  `,
  header: css`
    display: flex;
    padding: 30px 10px 10px 60px;
    @media (max-width: 768px) {
      padding: 0;
      flex-flow: column;
    }
  `,
  circle: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: grey;
    @media (max-width: 768px) {
      margin-top: 10px;
      margin-left: 10px;
    }
  `,
  index: css`
    color: white;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1rem;
    font-weight: 500;
  `,
  title: css`
    margin: 25px 25px 0 25px;
    color: black;
    font-size: 1.5rem;
    font-weight: 600;
  `,
  description: css`
    padding: 10px 10px 20px 60px;
    line-height: 3rem;
    opacity: 0.7;
    color: #222121;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    @media (max-width: 1280px) {
      font-size: 1.3vw;
      padding: 15px 30px;
      line-height: 2rem;
    }
    @media (max-width: 768px) {
      font-size: 0.7rem;
    }
  `,
  imgContainer: css`
    position: relative;
    margin: 30px;
    width: 35%;
    height: 80%;
    @media (max-width: 1280px) {
      width: 100%;
      margin: 0;
    }
  `,
}

export default FeatureCard
