import { useEffect } from 'react'
import { css } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import ServiceCard from '../molecules/ServiceCard'
import { returnFeed } from '~/utils/returnFeed'

const Service = () => {
  useEffect(() => {
    if (typeof window) {
      gsap.registerPlugin(ScrollTrigger)
      setAnimation()
    }
  }, [])

  return (
    <Box css={styles.container} component='section' id='service'>
      <Box css={styles.head}>
        <Typography variant='h1' css={styles.title} color='primary'>
          SERVICE
        </Typography>
        <Typography variant='h2' css={styles.subtitle} color='primary'>
          ２つの事業領域で学習塾・教室業などのウェブ集客をサポートします。
        </Typography>
      </Box>
      <Box component='ul' css={styles.cardContainer}>
        <li css={styles.listItem} className='s_card'>
          <ServiceCard
            src='/ad_hero.svg'
            title={returnFeed('ウェブ広告運用支援')}
            subtitle='Advertising Management'
            href='advertising'
          />
        </li>
        <li css={styles.listItem} className='s_card'>
          <ServiceCard
            src='/creative_hero.svg'
            title='サイト・LP制作支援'
            subtitle='Website/LP'
            href='creative'
          />
        </li>
      </Box>
    </Box>
  )
}

const styles = {
  container: css`
    max-width: 1180px;
    margin: 0 auto;
  `,
  head: css`
    padding: 150px 0 100px;
    @media (max-width: 1200px) {
      margin: 0 auto;
      padding: 50px 0;
      text-align: center;
    }
    @media (max-width: 960px) {
      padding: 50px 0 30px;
    }
  `,
  title: css`
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin: 40px 0;
    @media (min-width: 960px) {
      font-size: 4rem;
      text-align: left;
    }
  `,
  subtitle: css`
    line-height: 3rem;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    @media (max-width: 960px) {
      padding: 20px 40px 50px;
    }
  `,
  cardContainer: css`
    margin-bottom: 150px;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    @media (max-width: 960px) {
      display: flex;
      justify-content: center;
      flex-flow: column;
      padding: 0;
      margin-bottom: 0;
    }
  `,
  listItem: css`
    list-style: none;
  `,
}

const setAnimation = () => {
  gsap.from(
    '.s_card',
    // アニメーション内容
    {
      opacity: 0,
      y: 100,
      duration: 1,
      scrollTrigger: {
        trigger: '.s_card',
        start: 'top 80%',
      },
      stagger: 0.6,
    }
  )
}

export default Service
