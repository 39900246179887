import { css, Theme } from '@emotion/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade, Pagination } from 'swiper'

import HeroAdvertising from './HeroAdverising'
import HeroCreative from './HeroCreative'

import 'swiper/css/bundle'
import 'swiper/css'
import 'swiper/css/pagination'

const Carousel = () => {
  return (
    <Swiper
      modules={[Pagination, Autoplay, EffectFade]}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 10000,
      }}
      loop
      effect='fade'
      css={styles.slide}>
      <SwiperSlide>
        <HeroAdvertising />
      </SwiperSlide>
      <SwiperSlide>
        <HeroCreative />
      </SwiperSlide>
    </Swiper>
  )
}

const styles = {
  slide: (theme: Theme) => css`
    width: 100%;
    height: 100%;
    .swiper-pagination span {
      background-color: ${theme.palette.primary.main};
      width: 40px;
      border-radius: 5px;
    }
  `,
}

export default Carousel
