import { NextPage, InferGetStaticPropsType, GetStaticPropsContext } from 'next'
import Head from 'next/head'

import { initializeApollo } from '~/lib/apollo'
import {
  getServerPageGetAllNews,
  Stage,
  PostOrderByInput,
  NewsOrderByInput,
  WorkOrderByInput,
  getServerPageHomeposts,
  getServerPageHomeworks,
} from 'generated/graphql.api'

import HomeTemplate from '~/components/templates/HomeTemplate'

export type HomeProps = InferGetStaticPropsType<typeof getStaticProps>

const App: NextPage<HomeProps> = ({ data, news, works }) => {
  return (
    <>
      <Head>
        <title>eduad -エデュアド- | 塾・教室業専門のウェブ集客支援</title>
        <meta
          name='description'
          content='eduad-エデュアド-は教育・教室業に特化したウェブマーケティング支援を行うプロフェッショナル技術者集団です。ウェブ広告運用・クリエイティブ制作・ウェブサイト構築まで幅広く集客支援を行います。'
        />
      </Head>
      <HomeTemplate data={data} news={news} works={works} />
    </>
  )
}

export const getStaticProps = async (ctx: GetStaticPropsContext) => {
  const client = initializeApollo(ctx)
  const posts = await getServerPageHomeposts(
    {
      variables: {
        first: 6,
        skip: 0,
        orderBy: PostOrderByInput.PublishedAtDesc,
      },
    },
    client
  )
  const news = await getServerPageGetAllNews(
    {
      variables: {
        first: 3,
        skip: 0,
        orderBy: NewsOrderByInput.DateDesc,
      },
    },
    client
  )
  const works = await getServerPageHomeworks(
    {
      variables: {
        first: 3,
        skip: 0,
        stage: Stage.Published,
        orderBy: WorkOrderByInput.PublishedAtDesc,
      },
    },
    client
  )

  return {
    props: {
      data: posts.props.data,
      news: news.props.data,
      works: works.props.data,
    },
    revalidate: 60,
  }
}

export default App
