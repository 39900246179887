import dynamic from 'next/dynamic'
import { css } from '@emotion/react'
import { Box, Typography } from '@mui/material'

const BlogCarousel = dynamic(
  () => import('~/components/molecules/BlogCarousel'),
  { ssr: false }
)
import { ArrowButton } from '~/components/atoms/ArrowButton'
import { HomeProps } from '~/pages/index'

const Blog: React.FC<Partial<HomeProps>> = props => {
  const data = props.data

  return (
    <section id='blog' css={styles.container}>
      <Box css={styles.head}>
        <Typography variant='h1' css={styles.title} color='primary'>
          BLOG
        </Typography>
        <Typography variant='h2' css={styles.subtitle} color='primary'>
          学習指導や教室運営・集客・マーケティングなどに役立つ情報を発信しております。
        </Typography>
      </Box>
      <Box css={styles.carouselContainer}>
        <BlogCarousel data={data} />
      </Box>
      <Box css={styles.button}>
        <ArrowButton url='blog' label={'すべて見る'} />
      </Box>
    </section>
  )
}

const styles = {
  container: css`
    width: 100vw;
  `,
  head: css`
    max-width: 1180px;
    margin: 0 auto;
    padding: 150px 0 100px;
    @media (max-width: 1200px) {
      margin: 0 auto;
      padding: 50px 0;
      text-align: center;
    }
    @media (max-width: 960px) {
      padding: 50px 0 30px;
    }
  `,
  title: css`
    font-family: 'Montserrat', sans-serif;
    font-size: 4rem;
    font-weight: 700;
    margin: 40px 0;
    @media (max-width: 960px) {
      font-size: 3rem;
      text-align: center;
    }
  `,
  subtitle: css`
    line-height: 3rem;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    @media (max-width: 960px) {
      padding: 20px 40px 50px;
    }
  `,
  carouselContainer: css`
    height: 550px;
    @media (max-width: 768px) {
      height: 450px;
    }
  `,
  button: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    margin-bottom: 100px;
  `,
}

export default Blog
