import { css } from '@emotion/react'
import { Typography, Box } from '@mui/material'

import { ArrowButton } from '../atoms/ArrowButton'
import WorksList from './WorksList'

import { HomeworksQuery } from 'generated/graphql.api'

type Props = {
  works: HomeworksQuery
}

const Works: React.FC<Props> = ({ works }) => {
  return (
    <Box css={styles.container} component='section'>
      <Typography variant='h1' css={styles.title} color='primary'>
        WORKS
      </Typography>
      <Typography variant='h2' css={styles.subtitle} color='primary'>
        実績の一覧はこちらからご覧いただけます。
      </Typography>
      <div css={styles.wrapper}>
        <WorksList data={works} />
        <ArrowButton url='works' label={'すべて見る'} />
      </div>
    </Box>
  )
}

const styles = {
  container: css`
    max-width: 1180px;
    margin: 0 auto;
  `,
  title: css`
    padding: 40px 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 4rem;
    font-weight: 700;
    @media (max-width: 960px) {
      font-size: 3rem;
      text-align: center;
    }
  `,
  subtitle: css`
    line-height: 3rem;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    @media (max-width: 960px) {
      text-align: center;
      padding: 20px 40px 50px;
    }
  `,
  wrapper: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 0 30px;
  `,
  cardContainer: css`
    width: 100%;
    height: 800px;
    padding: 50px 0 150px;
  `,
  cardWrapper: css`
    padding-right: 60px;
  `,
}

export default Works
