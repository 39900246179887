import { useEffect } from 'react'
import { css } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import FeatureCard from '../molecules/FeatureCard'

const Features = () => {
  useEffect(() => {
    if (typeof window) {
      gsap.registerPlugin(ScrollTrigger)
      setAnimation()
    }
  }, [])

  return (
    <Box css={styles.wrapper} component='section'>
      <Box css={styles.container}>
        <Box css={styles.head}>
          <Typography variant='h1' css={styles.title} color='primary'>
            FEATURES
          </Typography>
          <Typography variant='h2' css={styles.subtitle} color='primary'>
            eduadの３つの特長をご紹介します
          </Typography>
        </Box>
        <Box component='ul' css={styles.cardContainer}>
          <li css={styles.listItem} className='f_card1'>
            <FeatureCard
              index='01'
              title='学習塾・教室業専門のスタッフ'
              description='eduadには学習塾・教室業の集客のベテランが在籍しています。
            実際に教室運営、生徒指導・募集に携わる中で培った、保護者・生徒それぞれに有効なマーケティング・集客ノウハウをもとに、あなたの教室に最適な戦略をご提案します。'
              src='/professional.webp'
            />
          </li>
          <li css={styles.listItem} className='f_card2'>
            <FeatureCard
              index='02'
              title='一気通貫の支援体制'
              description='ウェブ広告のご出稿や、ランディングページの制作など単発のご依頼から、ウェブマーケティング全体の支援を行うこともできます。それぞれの専門領域をもつスタッフが幅広くワンストップで対応するので、
            クリエイティブや施策内容など、方向性にズレが生じにくく、高い成果につながります。'
              src='/onestop.webp'
            />
          </li>
          <li css={styles.listItem} className='f_card3'>
            <FeatureCard
              index='03'
              title='高い信頼性を驚くほど低コストで'
              description='Google公認資格・ウェブ解析士・IMA検定など、有資格者が支援を行います。
            広告運用やコンサルティングは内製化の支援も同時に行うため、自走可能なノウハウの蓄積ができ、最終的なコストダウンにもつなげることができます。'
              src='/credit.webp'
            />
          </li>
        </Box>
      </Box>
    </Box>
  )
}

const styles = {
  wrapper: css`
    width: 100vw;
  `,
  container: css`
    max-width: 1180px;
    margin: 0 auto;
    color: #222222;
  `,
  head: css`
    padding: 150px 0 100px;
    @media (max-width: 1200px) {
      margin: 0 auto;
      padding: 50px 0;
      text-align: center;
    }
    @media (max-width: 960px) {
      padding: 50px 0 30px;
    }
  `,
  title: css`
    font-family: 'Montserrat', sans-serif;
    font-size: 4rem;
    font-weight: 700;
    margin: 40px 0;
    @media (max-width: 960px) {
      font-size: 3rem;
      text-align: center;
    }
  `,
  subtitle: css`
    line-height: 3rem;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    @media (max-width: 960px) {
      padding: 20px 40px 50px;
    }
  `,
  cardContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding-inline-start: 0;
  `,
  listItem: css`
    list-style: none;
  `,
}

const setAnimation = () => {
  gsap.from(
    '.f_card1',
    // アニメーション内容
    {
      opacity: 0,
      y: 30,
      duration: 0.5,
      scrollTrigger: {
        trigger: '.f_card1',
        start: 'top 80%',
      },
      stagger: 0.6,
    }
  )
  gsap.from(
    '.f_card2',
    // アニメーション内容
    {
      opacity: 0,
      y: 30,
      duration: 0.5,
      scrollTrigger: {
        trigger: '.f_card2',
        start: 'top 80%',
      },
      stagger: 0.6,
    }
  )
  gsap.from(
    '.f_card3',
    // アニメーション内容
    {
      opacity: 0,
      y: 30,
      duration: 0.5,
      scrollTrigger: {
        trigger: '.f_card3',
        start: 'top 80%',
      },
      stagger: 0.6,
    }
  )
}

export default Features
