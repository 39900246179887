import { useEffect, useContext } from 'react'

import gsap from 'gsap'
import { SplitText } from 'gsap/dist/SplitText'
import { DrawSVGPlugin } from 'gsap/dist/DrawSVGPlugin'
import { css } from '@emotion/react'
import { Waveform } from '@uiball/loaders'
import { isMobile } from 'react-device-detect'

import { LoadingContext } from '~/context/LoadingContext'

export const Loading: React.FC = () => {
  const toggle = useContext(LoadingContext).toggleTrigger

  useEffect(() => {
    gsap.registerPlugin(SplitText, DrawSVGPlugin)
    if (typeof window) {
      setAnimation(toggle)
    }
  }, [])

  return (
    <>
      <div css={styles.wrapper} id='loadingBG'>
        <div css={styles.content} id='content'>
          <div id='mark'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              preserveAspectRatio='xMidYMid'
              width='486.72'
              height='771.17'
              viewBox='-30 -30 486.72 771.17'>
              <defs>
                <mask id='mask'>
                  <circle
                    id='mask-circle'
                    cx='220'
                    cy='380'
                    r='150'
                    css={styles.mask}
                  />
                </mask>
                <linearGradient id='g'>
                  <stop offset='0' stopColor='#1c719c' />
                  <stop offset='1' stopColor='#59a1bd' />
                </linearGradient>
              </defs>
              <circle
                cx='216.48'
                cy='358.43'
                r='175.35'
                id='circle'
                style={{ fill: 'url(#g)' }}
              />
              <polyline
                id='path01'
                points='482.06 4.53 139.26 356.73 233.96 454.33 313.76 373.13'
                css={styles.path01}
              />
              <polyline
                id='path02'
                points='4.66 766.63 347.46 414.43 252.86 316.83 172.96 397.93'
                css={styles.path02}
              />
              <path
                d='M384.86,375.03c-1.6,109.6-111.9,182.3-214,145.5-72.4-25.7-117.2-102.1-103.6-178.1,9.1-54.6,48-102.7,99.7-122.5,107-41.3,219.8,42.1,217.9,155.1h0Zm-1.4,0c-1.4-93.4-89.9-158.5-179.5-136.5-63.2,15.1-110.5,71.1-112.6,136.5-3.6,79.6,59.7,148.7,139.8,151,83.9,3.2,153.9-67.5,152.3-151h0Z'
                css={styles.circle}
                mask='url(#mask)'
              />
            </svg>
          </div>
          <div css={styles.container}>
            <div id='eduad' css={styles.eduad}>
              eduad
            </div>
            <div css={styles.loader_container} id='loader'>
              <Waveform size={40} color='#2f2f2f' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const styles = {
  wrapper: css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(#fff, #ebeced);
    display: grid;
    place-content: center;
    place-items: center;
  `,
  content: css`
    visibility: hidden;
  `,
  container: css`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: grid;
    place-content: center;
    place-items: center;
  `,
  eduad: css`
    font-family: 'Montserrat', sans-serif;
    font-display: swap;
    color: black;
    letter-spacing: 0.3rem;
    font-size: 4rem;
    font-weight: 900;
    padding-top: 140px;
  `,
  loader_container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40vw;
    padding-top: 30px;
  `,
  mask: css`
    fill: none;
    stroke: white;
    stroke-width: 80;
    transform-origin: center;
  `,
  path01: css`
    fill: none;
    stroke: white;
    stroke-width: 13;
  `,
  path02: css`
    fill: none;
    stroke: white;
    stroke-width: 13;
  `,
  circle: css`
    fill: white;
    stroke: white;
  `,
}

const setAnimation = (setter: () => void) => {
  const handler = event => {
    event.preventDefault()
  }
  document.addEventListener('touchmove', handler, { passive: false })
  document.addEventListener('mousewheel', handler, { passive: false })
  const mySplitText = new SplitText('#eduad', {
    type: 'chars',
  })
  const chars = mySplitText.chars

  gsap.set('#mark', {
    scale: isMobile ? 2 : 5,
    transformOrigin: 'center',
    y: -60,
  })
  gsap.set('#circle', { scale: 8, transformOrigin: 'center' })

  let tl = gsap.timeline()
  const init = () => {
    tl.from('#content', {
      ease: 'linear',
      autoAlpha: 0,
      onStart: () => {},
    })
      .fromTo(
        '#path01',
        { drawSVG: 0, duration: 1.4, ease: 'expo.inOut' },
        { drawSVG: '42% 100%' },
        1
      )
      .fromTo(
        '#path02',
        { drawSVG: 0, duration: 1.4, ease: 'expo.inOut' },
        { drawSVG: '45% 100%' },
        '<'
      )
      .from('#mask-circle', { drawSVG: 0, duration: 0.3 })
      .to('#mark', {
        scale: 0.25,
        duration: 0.8,
        ease: 'expo.inOut',
      })
      .to('#circle', { scale: 1, duration: 0.6, ease: 'expo.inOut' }, '<')
      .from(chars, { opacity: 0, y: 20, stagger: 0.05 })
      .from('#loader', { opacity: 0, duration: 0.4 }, '<')
      .to('#loadingBG', {
        y: -1500,
        duration: 1.5,
        ease: 'expo.inOut',
        onStart: () => {
          document.removeEventListener('touchmove', handler)
          document.removeEventListener('mousewheel', handler)
        },
      })
      .to('#loadingBG', {
        visibility: 'hidden',
        onComplete: () => {
          setter()
        },
      })
      .from('#copy', { y: 60, duration: 1, opacity: 0 }, '-=1')
      .from(
        '#cvbutton',
        {
          scale: 0,
          duration: 1.5,
          opacity: 0,
          transformOrigin: 'center',
          ease: 'expo.inOut',
        },
        '<'
      )
      .from(
        '#points',
        {
          scale: 0,
          duration: 1.5,
          opacity: 0,
          transformOrigin: 'center',
          ease: 'expo.inOut',
        },
        '<'
      )
      .from('#hero', { y: 400, duration: 1, opacity: 0 }, '<')
  }

  init()
}
