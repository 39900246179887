import { css } from '@emotion/react'

import HeroCarousel from '~/components/molecules/HeroCarousel'
import { ScrollDown } from '~/components/atoms/ScrollDown'

const Hero = () => {
  return (
    <section css={styles.wrapper} id='top'>
      <HeroCarousel />
      <div css={styles.scrollDown}>
        <ScrollDown label='SCROLL' link='concept' />
      </div>
    </section>
  )
}

const styles = {
  wrapper: css`
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 90vh;
    min-height: 600px;
    @media (min-width: 960px) {
      min-height: 1000px;
    }
  `,
  scrollDown: css`
    position: absolute;
    z-index: 10;
    bottom: 0px;
    right: 20px;
    height: 300px;
    overflow: hidden;
    @media (min-width: 960px) {
      right: 80px;
    }
  `,
}

export default Hero
